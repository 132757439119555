import { toast } from "react-toastify";
import Axios from "../Axios";

export const getGratuitySettings = async () => {
    try {
        const res = await Axios.get(`setting/gratuity/show`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const postGratuitySettings = async (data) => {
    try {
        const res = await Axios.post(`setting/gratuity`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getProvidendSettings = async () => {
    try {
        const res = await Axios.get(`setting/provident-fund/show`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const postProvidentSettings = async (data) => {
    try {
        const res = await Axios.post(`setting/provident-fund`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
