import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";

//Style

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <AiOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function NewFormModal({
  openModal,
  handleModalClose,
  modalTitle,
  children,
  modalWidth,
}) {
  console.log("modalWidth:", modalWidth)
  return (
    <BootstrapDialog
      onClose={handleModalClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      maxWidth={modalWidth || "md"}
      sx={{
        ".MuiDialog-container .MuiPaper-root.MuiPaper-elevation": {
          width: "100%",
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleModalClose}
        textTransform={"capitalize"}
      >
        {modalTitle}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box>{children}</Box>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default NewFormModal;
