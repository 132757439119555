import { useQuery } from "@tanstack/react-query";
import { getLoanApproverHistory, getLoanApprovers } from "../api/fetchers/loanFetcher";

const useGetLoanApproveHistory = ({ id }) => {
    return useQuery({
        queryKey: ["loan-approve-history", id],
        queryFn: () => getLoanApproverHistory(id),
    });
};

export default useGetLoanApproveHistory;
