import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, FormControl, FormHelperText, Grid, OutlinedInput, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import ApiErrorMessage from "../../components/error/ApiErrorMessage";

const validationSchema = Yup.object().shape({
    loanType: Yup.string().required("loan name is required"),
});

const AddLoanType = ({ handleAddSubmit, handleError }) => {
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    return (
        <>
            <form onSubmit={handleSubmit(handleAddSubmit)}>
                <Controller
                    render={({ field, formState }) => (
                        <FormControl fullWidth variant="outlined" size="small">
                            <Typography color={!!formState.errors?.loanType ? red[700] : ""}>Loan Type Name*</Typography>
                            <OutlinedInput {...field} error={!!formState.errors?.loanType} placeholder="Enter Loan Name" />
                            {!!formState.errors?.loanType ? <FormHelperText error>{errors?.loanType?.message}</FormHelperText> : ""}
                        </FormControl>
                    )}
                    name="loanType"
                    control={control}
                    defaultValue=""
                />
                <ApiErrorMessage handleError={handleError} />
                <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={() => reset()} color="inherit" fullWidth>
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" type="submit" fullWidth>
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default AddLoanType;
