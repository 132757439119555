import { Button, Divider, IconButton, Menu, MenuItem, Pagination, PaginationItem, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Axios from "../../api/Axios";
import BranchTable from "../../components/BranchTable/BranchTable";
import DataNotFound from "../../components/DataNotFound";
import DeleteModal from "../../components/Modal/DeleteModal";
import NewFormModal from "../../components/Modal/NewFormModal";
import Search from "../../components/Search/Search";
import ErrorMessage from "../../components/error/ErrorMessage";
import { ROLENAME, checkAllPermissionList } from "../../helpers/commonUses";
import GetUserPermission from "../../hooks/permission/GetUserPermission";
import { useAuth } from "../../hooks/useAuth";
import LoadingPage from "../LoadingPage/LoadingPage";
import EditJobContact from "./EditLoanType";
import GetRolePermission from "../../hooks/permission/GetRolePermission";
import AddLoanType from "./AddLoanType";
import EditLoanType from "./EditLoanType";

//Table Style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#174d82",
        color: theme.palette.common.white,
        padding: "12px 12px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "12px 12px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

//Menu Style
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

function LoanTypeList() {
    const [branchList, setBranchList] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [countPage, setCountPage] = useState(10);
    const [error, setError] = useState({
        isError: false,
        errorMessage: null,
    });
    const [isAddBranchModal, setIsAddBranchModal] = useState(false);
    const [isEditBranchModal, setIsEditBranchModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [isClearSearch, setIsClearSearch] = useState(false);
    const { storePermissionPath } = GetUserPermission();
    const { storeRolePermissionPath } = GetRolePermission();

    //Router Path
    const routerPermissionPath = ["api/type-of-loan/create", "api/type-of-loan/update/{id}", "api/type-of-loan/delete/{id}"];

    //Context
    const { role, token } = useAuth();
    const navigate = useNavigate();

    //react-hook-form
    const { reset } = useForm({
        mode: "onChange",
    });

    //Menu
    const open = Boolean(anchorEl);
    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentIndex(index);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Pagination
    const changePagination = (e, value) => {
        setPage(value);
    };

    //Search
    const handleSearchSubmit = (formData) => {
        if (page !== 1) {
            setPage(1);
        }
        setSearchValue(formData?.text);
        setIsClearSearch(true);
        setShouldFetchData((prev) => !prev);
    };

    //Clear Search
    const handleClearSearch = () => {
        if (page !== 1) {
            setPage(1);
        }
        setSearchValue(null);
        setIsClearSearch(false);
        setShouldFetchData((prev) => !prev);
    };

    //Open Modal
    const handleOpenAddModal = () => {
        setIsAddBranchModal(true);
        setErrorMessage(null);
    };
    const handleCloseAddModal = () => {
        setIsAddBranchModal(false);
    };

    //Edit Modal
    const handleOpenEditModal = () => {
        setIsEditBranchModal(true);
        setErrorMessage(null);
        handleClose();
    };
    const handleCloseEditModal = () => {
        setIsEditBranchModal(false);
    };

    //Add branch submit
    const handleAddSubmit = async (data) => {
        try {
            const res = await Axios.post(
                "/type-of-loan/create",
                {
                    title: data.loanType,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (res?.data?.status === "success") {
                toast.success("Loan Added");
                setIsAddBranchModal(false);
                setShouldFetchData((prev) => !prev);
                reset();
                setErrorMessage(null);
            } else if (res.data.success === "200") {
                toast.info("You don't have Loan type create  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            } else if (res?.data?.status === "error") {
                toast.warn(res?.data?.error[0]);
                setErrorMessage(null);
            }
        } catch (error) {
            console.log(error);
            toast.warn("Something went wrong");
            setErrorMessage(error?.response?.data?.error);
        }
    };

    //Edit branch submit
    const handleEditSubmit = async (data) => {
        try {
            const res = await Axios.post(
                `/type-of-loan/update/${currentIndex}`,
                {
                    title: data.loanType,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (res?.data?.status === "success") {
                toast.success("Loan Updated Successfully");
                setIsEditBranchModal(false);
                setShouldFetchData((prev) => !prev);
                setErrorMessage(null);
            } else if (res.data.success === "200") {
                toast.info("You don't have Loan type update  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            } else if (res?.data?.status === "error") {
                toast.warn(res?.data?.message);
                setErrorMessage(null);
            }
        } catch (error) {
            console.log(error);
            toast.warn("Something went wrong");
            setErrorMessage(error?.response?.data?.errors);
        }
    };

    //show delete modal
    const handleShowDeleteModal = () => {
        setIsDeleteModalShow(true);
        handleClose();
    };
    const handleHideDeleteModal = () => {
        setIsDeleteModalShow(false);
    };

    //Delete Branch
    const handleDeleteBranch = async () => {
        try {
            const res = await Axios.delete(`/type-of-loan/delete/${currentIndex}`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });

            if (res?.data?.status === "success") {
                toast.success("Loan Type delete successfully");
                handleClose();
                setIsDeleteModalShow(false);
                //for last data delete to fix bug
                if (branchList?.length === 1 && page !== 1) {
                    setPage(1);
                }
                setShouldFetchData(!shouldFetchData);
            } else if (res.data.success === "200") {
                toast.info("You don't have Loan type delete  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            } else if (res?.data?.status === "error") {
                toast.warn(res?.data?.error[0]);
            }
        } catch (error) {
            console.log(error);
            toast.warn("Something went wrong");
            setLoading(false);
        }
    };

    //Get Brach Data
    const getBranchList = async () => {
        try {
            setLoading(true);
            const res = await Axios.get(
                "/type-of-loan",

                {
                    params: {
                        title: searchValue,
                        page: page,
                    },
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (res.data.status === "success") {
                setBranchList(res?.data?.response_data?.data);
                setCountPage(res?.data?.response_data?.last_page);
                setError({
                    isError: false,
                    errorMessage: null,
                });
            } else if (res.data.success === "200") {
                toast.info("You don't have Loan type list  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            } else {
                toast.warn("Something went wrong");
                setBranchList([]);
            }
            setLoading(false);
        } catch (error) {
            console.log("branch list", error);
            // toast.warn("Something went wrong");
            setLoading(false);
            setBranchList([]);
            if (error?.response?.status !== 422) {
                setError({
                    isError: true,
                    errorMessage: error?.response?.data?.message,
                });
            }

            if (error?.response?.status === 422) {
                toast.warn("Data not found");
            } else {
                toast.warn("Something went wrong");
            }
        }
    };

    useEffect(() => {
        getBranchList();
    }, [page, shouldFetchData]);

    if (loading) {
        return <LoadingPage />;
    }
    if (error?.isError) {
        return <ErrorMessage message={error?.errorMessage} />;
    }
    return (
        <>
            <BranchTable>
                <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={"wrap"} gap={2} sx={{ mb: 3 }}>
                    <Typography variant="h5" color="#0F3F62">
                        Loan Type List
                    </Typography>
                    {role === ROLENAME ? (
                        <Button variant="contained" startIcon={<AiOutlinePlus />} sx={{ m: 0 }} onClick={handleOpenAddModal}>
                            Add Loan Type
                        </Button>
                    ) : (
                        (storePermissionPath.includes(routerPermissionPath[0]) || storeRolePermissionPath.includes(routerPermissionPath[0])) && (
                            <Button variant="contained" startIcon={<AiOutlinePlus />} sx={{ m: 0 }} onClick={handleOpenAddModal}>
                                Add Loan Type
                            </Button>
                        )
                    )}
                </Stack>
                <Divider />
                <Search
                    searchValue={searchValue}
                    handleSearchSubmit={handleSearchSubmit}
                    setSearchValue={setSearchValue}
                    handleClearSearch={handleClearSearch}
                    isClearSearch={isClearSearch}
                />
                <TableContainer sx={{ mt: 2 }}>
                    <Table sx={{ width: "100%" }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>#</StyledTableCell>
                                <StyledTableCell align="center">Loan Type Name</StyledTableCell>
                                <StyledTableCell align="right">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {branchList?.length <= 0 ? (
                                <StyledTableCell component="th" scope="row" colSpan={4}>
                                    <DataNotFound />
                                </StyledTableCell>
                            ) : (
                                branchList.map((row, index) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell component="th" scope="row">
                                            {page !== 1 && index + 1 === 10 ? page : page !== 1 && page - 1}
                                            {index + 1 === 10 && page !== 1 ? 0 : index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.title || "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {role === ROLENAME ? (
                                                <IconButton onClick={(e) => handleClick(e, row?.id)} aria-label="Action" size="small">
                                                    <BsThreeDotsVertical />
                                                </IconButton>
                                            ) : checkAllPermissionList(
                                                  routerPermissionPath.slice(1),
                                                  storePermissionPath?.concat(storeRolePermissionPath)
                                              ) ? (
                                                <>
                                                    {" "}
                                                    <IconButton onClick={(e) => handleClick(e, row?.id)} aria-label="Action" size="small">
                                                        <BsThreeDotsVertical />
                                                    </IconButton>{" "}
                                                </>
                                            ) : (
                                                "N/A"
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <StyledMenu
                    id={`branchDropdown`}
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {/* <MenuItem onClick={handleClose}>
                      <Link
                        to={"/edit-branch"}
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "10px",
                          color: "black",
                          width: "100%",
                        }}
                      >
                        <AiOutlineEye />
                        View
                      </Link>
                    </MenuItem> */}
                    {role === ROLENAME ? (
                        <MenuItem
                            onClick={handleOpenEditModal}
                            sx={{
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                gap: "10px",
                                color: "black",
                                width: "100%",
                                border: "none",
                                background: "transparent",
                                fontSize: 14,
                                cursor: "pointer",
                            }}
                        >
                            <FaRegEdit />
                            Edit
                        </MenuItem>
                    ) : (
                        (storePermissionPath.includes(routerPermissionPath[1]) || storeRolePermissionPath.includes(routerPermissionPath[1])) && (
                            <MenuItem
                                onClick={handleOpenEditModal}
                                sx={{
                                    textDecoration: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                    color: "black",
                                    width: "100%",
                                    border: "none",
                                    background: "transparent",
                                    fontSize: 14,
                                    cursor: "pointer",
                                }}
                            >
                                <FaRegEdit />
                                Edit
                            </MenuItem>
                        )
                    )}
                    {role === ROLENAME ? (
                        <MenuItem onClick={handleShowDeleteModal}>
                            <button
                                type="button"
                                style={{
                                    textDecoration: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                    color: "black",
                                    width: "100%",
                                    border: "none",
                                    background: "transparent",
                                    padding: "0",
                                    cursor: "pointer",
                                }}
                            >
                                <RiDeleteBin6Line />
                                Delete
                            </button>
                        </MenuItem>
                    ) : (
                        (storePermissionPath.includes(routerPermissionPath[2]) || storeRolePermissionPath.includes(routerPermissionPath[2])) && (
                            <MenuItem onClick={handleShowDeleteModal}>
                                <button
                                    type="button"
                                    style={{
                                        textDecoration: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        gap: "10px",
                                        color: "black",
                                        width: "100%",
                                        border: "none",
                                        background: "transparent",
                                        padding: "0",
                                        cursor: "pointer",
                                    }}
                                >
                                    <RiDeleteBin6Line />
                                    Delete
                                </button>
                            </MenuItem>
                        )
                    )}
                </StyledMenu>
                {page === 1 && branchList?.length < 10 ? null : (
                    <Stack spacing={2} alignItems="end" mt={3}>
                        <Pagination
                            count={countPage}
                            page={page}
                            onChange={changePagination}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: AiOutlineArrowLeft,
                                        next: AiOutlineArrowRight,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                )}
            </BranchTable>
            {/* Add Modal  */}
            <NewFormModal openModal={isAddBranchModal} handleModalClose={handleCloseAddModal} modalTitle="Add New Loan Type" modalWidth="sm">
                <AddLoanType handleAddSubmit={handleAddSubmit} handleError={errorMessage} />
            </NewFormModal>

            {/* Edit Modal  */}
            <NewFormModal openModal={isEditBranchModal} handleModalClose={handleCloseEditModal} modalTitle="Edit Loan Type" modalWidth="sm">
                <EditLoanType handleAddSubmit={handleEditSubmit} handleError={errorMessage} id={currentIndex} />
            </NewFormModal>

            {/* Delete Modal  */}
            <DeleteModal
                dialogOpen={isDeleteModalShow}
                handleDialogClose={handleHideDeleteModal}
                handleDelete={handleDeleteBranch}
                modifyText={"Loan type"}
            />
        </>
    );
}

export default LoanTypeList;
