import { useQuery } from "@tanstack/react-query";
import { getLoanRequest } from "../api/fetchers/loanFetcher";

const useGetLoanRequest = ({ id }) => {
    return useQuery({
        queryKey: ["loan-request", id],
        queryFn: () => getLoanRequest(id),
    });
};

export default useGetLoanRequest;
