import { useQuery } from "@tanstack/react-query";
import { getAllEmployees } from "../api/fetchers/dropdownData";

const useGetAllEmployees = () => {
    return useQuery({
        queryKey: ["all-employees"],
        queryFn: () => getAllEmployees(),
    });
};

export default useGetAllEmployees;
