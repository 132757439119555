import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    maxHeight: 800,
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const employees = [
    {
        SL: 1,
        EmpID: "1023",
        EmpName: "Sara Ahmed",
        TotalWorkingYears: 5,
        TotalFundAmount: 25000,
        Status: "Active",
    },
    {
        SL: 2,
        EmpID: "2156",
        EmpName: "Rahul Sharma",
        TotalWorkingYears: 8,
        TotalFundAmount: 35000,
        Status: "Active",
    },
    {
        SL: 3,
        EmpID: "3789",
        EmpName: "Aisha Khan",
        TotalWorkingYears: 3,
        TotalFundAmount: 15000,
        Status: "Inactive",
    },
    {
        SL: 4,
        EmpID: "4912",
        EmpName: "Imran Ali",
        TotalWorkingYears: 10,
        TotalFundAmount: 45000,
        Status: "Active",
    },
    {
        SL: 5,
        EmpID: "5643",
        EmpName: "Fiona Rahman",
        TotalWorkingYears: 6,
        TotalFundAmount: 28000,
        Status: "Active",
    },
    {
        SL: 6,
        EmpID: "6789",
        EmpName: "Khaled Hussain",
        TotalWorkingYears: 2,
        TotalFundAmount: 12000,
        Status: "Inactive",
    },
    {
        SL: 7,
        EmpID: "7321",
        EmpName: "Nadia Begum",
        TotalWorkingYears: 7,
        TotalFundAmount: 32000,
        Status: "Active",
    },
    {
        SL: 8,
        EmpID: "8456",
        EmpName: "Tariq Rahman",
        TotalWorkingYears: 4,
        TotalFundAmount: 18000,
        Status: "Inactive",
    },
    {
        SL: 9,
        EmpID: "9087",
        EmpName: "Lina Khan",
        TotalWorkingYears: 9,
        TotalFundAmount: 40000,
        Status: "Active",
    },
    {
        SL: 10,
        EmpID: "9999",
        EmpName: "Ahmed Hassan",
        TotalWorkingYears: 5,
        TotalFundAmount: 25000,
        Status: "Active",
    },
    // Add more employees as needed
];

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required("Cannot generate report without employee name"),
    month: Yup.string().required("Cannot generate report without month"),
    year: Yup.string().required("Cannot generate report without year"),
});

const ProvidentFundReport = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedFund, setSelectedFund] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleMenuOpen = (event, selectedFund) => {
        setSelectedFund(selectedFund);
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });
    const onSubmit = (data) => console.log(data);

    return (
        <Paper elevation={2} sx={{ p: 2 }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h5">Provident Fund Report</Typography>
                <Stack direction={"row"} alignItems={"center"}>
                    <TextField id="search" label="Search" variant="outlined" size="small" />
                    <Button variant="contained" size="small" color="primary" sx={{ ml: 2 }}>
                        Search
                    </Button>
                </Stack>
                {/* <Button
                    sx={{ mr: 1 }}
                    variant={"contained"}
                    size={"small"}
                    color={"success"}
                    onClick={handleOpen}
                >
                    Add Loan
                </Button> */}
            </Stack>
            <Divider sx={{ my: 2 }}></Divider>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">SL</TableCell>
                            <TableCell align="center">Emp ID</TableCell>
                            <TableCell align="center">Emp Name</TableCell>
                            <TableCell align="center">Total Working Years</TableCell>
                            <TableCell align="center">Total Fund Amount</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.map((employee, index) => (
                            <TableRow key={index}>
                                <TableCell align="left">{employee.SL}</TableCell>
                                <TableCell align="center">{employee.EmpID}</TableCell>
                                <TableCell align="center">{employee.EmpName}</TableCell>
                                <TableCell align="center">{employee.TotalWorkingYears}</TableCell>
                                <TableCell align="center">{employee.TotalFundAmount}</TableCell>
                                <TableCell align="center">
                                    <Chip
                                        label={employee.Status}
                                        size="small"
                                        color={employee.Status === "Active" ? "success" : "warning"}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={100}
                    page={1}
                    // onPageChange={handleChangePage}
                    rowsPerPage={10}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem>
                    <Link to={`/provident-fund/${selectedFund.EmpID}`}>View Details</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    Set {selectedFund.Status === "Active" ? "Inactive" : "Active"}
                </MenuItem>
            </Menu>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Grant Loan
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            container
                            spacing={2}
                            sx={{ my: 2 }}
                            alignItems="center"
                            justifyContent="end"
                        >
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography
                                                color={!!formState.errors?.position ? red[700] : ""}
                                            >
                                                Employee Name*
                                            </Typography>
                                            <Select {...field} error={!!formState.errors?.position}>
                                                <MenuItem value={"intern software engineer"}>
                                                    Intern Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"trainee software engineer"}>
                                                    Trainee Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"associate software engineer"}>
                                                    Associate Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"software engineer"}>
                                                    Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"senior software engineer"}>
                                                    Senior Software Engineer
                                                </MenuItem>
                                            </Select>
                                            {!!formState.errors?.position ? (
                                                <FormHelperText error>
                                                    {errors?.position?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="position"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography
                                                color={!!formState.errors?.position ? red[700] : ""}
                                            >
                                                Permitted By*
                                            </Typography>
                                            <Select {...field} error={!!formState.errors?.position}>
                                                <MenuItem value={"intern software engineer"}>
                                                    Intern Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"trainee software engineer"}>
                                                    Trainee Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"associate software engineer"}>
                                                    Associate Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"software engineer"}>
                                                    Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"senior software engineer"}>
                                                    Senior Software Engineer
                                                </MenuItem>
                                            </Select>
                                            {!!formState.errors?.position ? (
                                                <FormHelperText error>
                                                    {errors?.position?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="position"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Loan Details
                                            </Typography>
                                            <TextField
                                                multiline
                                                rows={3}
                                                {...field}
                                                error={!!formState.errors?.startDate}
                                            />

                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Approve Date*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"date"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Repayment From*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"date"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Amount*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Percentage (%)*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Installment Period*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Repayment Total*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Installment ($)*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography
                                                color={!!formState.errors?.position ? red[700] : ""}
                                            >
                                                Status*
                                            </Typography>
                                            <Select {...field} error={!!formState.errors?.position}>
                                                <MenuItem value={"intern software engineer"}>
                                                    Intern Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"trainee software engineer"}>
                                                    Trainee Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"associate software engineer"}>
                                                    Associate Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"software engineer"}>
                                                    Software Engineer
                                                </MenuItem>
                                                <MenuItem value={"senior software engineer"}>
                                                    Senior Software Engineer
                                                </MenuItem>
                                            </Select>
                                            {!!formState.errors?.position ? (
                                                <FormHelperText error>
                                                    {errors?.position?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="position"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button sx={{ mt: 3 }} variant="contained" type="submit" fullWidth>
                                    Grant Loan
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </Paper>
    );
};

export default ProvidentFundReport;
