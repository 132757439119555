import { toast } from "react-toastify";
import Axios from "../Axios";

export const getManageProvidentFund = async () => {
    try {
        const res = await Axios.get(`provident-funds/manage-provident-fund`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
