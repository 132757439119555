import { Divider, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

const GratuityDetails = () => {
    return (
        <Paper className="gratuity-page" elevation={1} sx={{ px: 2, py: 1 }}>
            <h3>Employee Gratuity Details</h3>
            <Divider />
            {/* Add your content here */}
            <h3 style={{ marginBottom: 0, textAlign: "center" }}>Mr. Ibrahim Khalil</h3>
            <h5 style={{ marginTop: 0, textAlign: "center" }}>Technical Analyst</h5>

            <Table
                size="small"
                sx={{ maxWidth: "50%", border: "1px solid #dcdcdc", margin: "0 auto" }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell align="right">Contract Type</TableCell>
                        <TableCell align="left">Full Time</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">Joining Date</TableCell>
                        <TableCell align="left">07/01/2015</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">Basic Salary</TableCell>
                        <TableCell align="left">55,000 / month</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">Total Years Worked</TableCell>
                        <TableCell align="left">8 years</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">Total Leaves Taken</TableCell>
                        <TableCell align="left">127 days</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">JGratuity Years</TableCell>
                        <TableCell align="left">7 years and 238 days</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">Gratuity Amount</TableCell>
                        <TableCell align="left">420,750</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
};

export default GratuityDetails;
