import { useQuery } from "@tanstack/react-query";
import { getAllLoanTypes } from "../api/fetchers/loanFetcher";

const useGetAllLoanTypes = () => {
    return useQuery({
        queryKey: ["all-loan-types"],
        queryFn: () => getAllLoanTypes(),
    });
};

export default useGetAllLoanTypes;
