import { toast } from "react-toastify";
import Axios from "../Axios";

export const getLoanRequests = async () => {
    try {
        const res = await Axios.get(`loan-request`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getLoanRequest = async (id) => {
    try {
        const res = await Axios.get(`loan-request/show/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const createLoanRequest = async (data) => {
    try {
        const res = await Axios.post(`loan-request/create`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllLoanTypes = async () => {
    try {
        const res = await Axios.get(`type-of-loan/all`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getLoanApprovers = async (page, limit) => {
    try {
        const res = await Axios.get(`loan-approval-setting`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
                page,
                limit,
            },
        });

        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getLoanApproverHistory = async (id) => {
    try {
        const res = await Axios.get(`loan-request/loan-approve-history/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const approveLoanRequest = async (data, id, type) => {
    try {
        const res = await Axios.post(`loan-request/${type}/approval-status/${id}`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
