import { toast } from "react-toastify";
import Axios from "../Axios";

export const getEmployeeDocs = async (page, rowsPerPage) => {
    try {
        const res = await Axios.get(`employee-internal-document`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
                page: page + 1,
                limit: rowsPerPage,
            },
        });

        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const generateDoc = async (data) => {
    try {
        const res = await Axios.post(`employee-internal-document/create`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data.response_data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

// export const getAllLoanTypes = async () => {
//     try {
//         const res = await Axios.get(`type-of-loan/all`, {
//             headers: {
//                 Authorization: "Bearer " + localStorage.getItem("token"),
//             },
//         });

//         return res.data.response_data;
//     } catch (error) {
//         console.log("error", error);
//         toast.warn("Something went wrong");
//     }
// };
