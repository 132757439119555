import {
    Box,
    Button,
    Grid,
    MenuItem,
    Select,
    Stack,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    TableBody,
    CircularProgress,
} from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { useEffect, useState } from "react";
import Axios from "../../../api/Axios";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { renderFile } from "../../../utils/utils";
import { useMutation } from "@tanstack/react-query";
import { deleteDocumentById } from "../../../api/fetchers/employeeFetcher";
import CircularLoader from "../../common/CircularLoader";

function AttachmentTabNew() {
    const [documentType, setDocumentType] = useState([]);

    //loading state
    const [loading, setLoading] = useState(false);
    //document states
    const [existingDocument, setExistingDocument] = useState([]);
    const [document, setDocument] = useState(null);
    const [documentName, setDocumentName] = useState(null);
    const [documentNote, setDocumentNote] = useState(null);
    const [refetch, setRefetch] = useState(false);

    //Router dom
    const navigate = useNavigate();
    const { id } = useParams();

    //Context
    const { token } = useAuth();
    const { basicEmployeeInfo, handleShowTabCondition } = useEmployeeContext();

    //new attatchment
    const getAllLeaveType = async () => {
        setLoading(true);
        try {
            const response = await Axios.get(`document-type/all`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log(response.data.response_data);
            setDocumentType(response.data.response_data);
        } catch (error) {
            console.log(error.response.status);
        } finally {
            setLoading(false);
        }
    };

    //new attatchment
    const getEmployeeAttachment = async () => {
        setLoading(true);
        try {
            const response = await Axios.get(`employee/attachment/show/${id ? id : basicEmployeeInfo?.id}`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log(response.data.response_data);
            setExistingDocument(response.data.response_data);
            setRefetch((prev) => !prev);
        } catch (error) {
            console.log(error.response.status);
            setRefetch((prev) => !prev);
        } finally {
            setLoading(false);
            setRefetch(false);
        }
    };

    //new attatchment
    const uploadDocument = async () => {
        const formData = new FormData();
        formData.append("file", document);
        formData.append("document_type_id", documentName);
        formData.append("description", documentNote);
        formData.append("employee_id", id ? id : basicEmployeeInfo?.id);
        setLoading(true);
        setRefetch(true);
        try {
            const response = await Axios.post(`employee/attachment`, formData, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            if (response?.data?.status === "success") {
                toast.success("Document upload successfully");
                handleShowTabCondition("reference");
                setRefetch((prev) => !prev);
            } else if (response.data.success === "200") {
                toast.info("You don't have document upload  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            }
        } catch (error) {
            console.log(error.response.status);
        } finally {
            setLoading(false);
            setDocumentName(null);
            setDocument(null);
            setDocumentNote(null);
        }
    };

    //get office schedule
    useEffect(() => {
        getAllLeaveType();
    }, []);

    //get all attachments
    useEffect(() => {
        getEmployeeAttachment();
    }, [refetch]);

    //on upload button click
    const onUpload = async () => {
        uploadDocument();
    };

    const { mutateAsync } = useMutation({
        mutationFn: (data) => deleteDocumentById(data?.id),
        onSuccess: (response) => {
            if (response?.status === "success") {
                toast.success("Document deleted successfully");
                setRefetch(true);
            } else {
                toast.error(response?.message);
            }
        },
        onError: (error) => {
            console.log(error);
        },
    });
    const handleDeleteDocument = async (documentId) => {
        await mutateAsync({ id: documentId });
    };

    if (loading) {
        return <CircularLoader size={40} />;
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Stack>
                        <Typography>Document Type</Typography>
                        <Select size="small" disabled={loading} onChange={(e) => setDocumentName(e.target.value)}>
                            {documentType.map((item) => (
                                <MenuItem value={item.id} key={item?.id}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Stack>
                        <Typography>Note</Typography>
                        <TextField size="small" fullWidth onChange={(e) => setDocumentNote(e.target.value)} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Stack>
                        <Typography>Document</Typography>
                        <TextField size="small" fullWidth type="file" onChange={(e) => setDocument(e.target.files[0])} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button disabled={loading || !document || !documentName} variant="contained" fullWidth sx={{ mt: 3 }} onClick={onUpload}>
                        Upload
                    </Button>
                </Grid>
            </Grid>
            {existingDocument.length > 0 && (
                <TableContainer sx={{ mt: 4 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Document Type</TableCell>
                                <TableCell align="left">Note</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {existingDocument.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{row.document_type.document_type_name}</TableCell>
                                    <TableCell align="left">{row.description}</TableCell>
                                    <TableCell align="right">
                                        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} gap={1.5}>
                                            <div>
                                                <a href={renderFile(row.file)} target="_blank" rel="noreferrer">
                                                    <AiFillEye
                                                        size={20}
                                                        style={{
                                                            color: "turquoise",
                                                        }}
                                                    />
                                                </a>
                                            </div>

                                            <div>
                                                <AiFillDelete
                                                    onClick={() => handleDeleteDocument(row?.id)}
                                                    cursor="pointer"
                                                    size={20}
                                                    color="#f04b2e"
                                                />
                                            </div>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
}

export default AttachmentTabNew;
