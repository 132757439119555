import { PiSealWarningBold } from "react-icons/pi";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { GoHourglass } from "react-icons/go";
import {
    Alert,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { IoMdCloseCircle } from "react-icons/io";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetLoanRequest from "../../hooks/useGetLoanRequestWithId";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import useGetLoanApproversList from "../../hooks/useGetLoanApproversList";
import useGetLoanApproveHistory from "../../hooks/useGetLoanApproveHistory";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useAuth } from "../../hooks/useAuth";
import { MdTouchApp } from "react-icons/md";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { approveLoanRequest } from "../../api/fetchers/loanFetcher";

const LoanDetails = () => {
    const [loanHistory, setloanHistory] = React.useState([]);
    const [loanStatus, setLoanStatus] = React.useState(true);
    const [currentLoanTimeline, setCurrentLoanTimeline] = React.useState({});
    const [remarks, setRemarks] = React.useState("");
    const [currentLoanStatus, setCurrentLoanStatus] = React.useState("Pending");
    const { id } = useParams();
    const { data: loanDetails, isLoading: loanDetailsLoading, isError: loanDetailsError } = useGetLoanRequest({ id });
    const {
        data: loanApprovers,
        isLoading: loanApproversLoading,
        isError: loanApproversError,
    } = useGetLoanApproversList({ page: null, limit: null });

    const { data: loanApproverHistory, isLoading: loanApproverHistoryLoading, isError: loanApproverHistoryError } = useGetLoanApproveHistory({ id });

    const { user } = useAuth();

    const queryClient = useQueryClient();

    const loanStatusMutation = useMutation({
        mutationFn: (statusValue) =>
            approveLoanRequest({ remarks, approval_status: statusValue }, currentLoanTimeline.id ?? id, currentLoanTimeline.id ? "update" : "change"),
        onSuccess: () => {
            queryClient.invalidateQueries("loan-approve-history");
            queryClient.invalidateQueries("loan-request");
            handleClose();
        },
    });

    const statusApprove = () => {
        loanStatusMutation.mutate("approved");
        console.log("Approve", currentLoanTimeline.id);
    };

    const statusReject = () => {
        loanStatusMutation.mutate("canceled");
    };

    useEffect(() => {
        const loanStatusCopy = [];
        if (loanApprovers) {
            loanApprovers.forEach((approver) => {
                loanStatusCopy.push({
                    approverName: approver?.employee_name,
                    approverId: approver?.employee_id,
                    approverRank: approver?.rank,
                    approveDate: null,
                    approveRemark: null,
                    approvalStatus: null,
                    id: null,
                });
            });
        }
        if (loanApproverHistory) {
            loanApproverHistory.forEach((history) => {
                if (history.approval_status === "pertially_approved") {
                    setCurrentLoanStatus("Partially Approved");
                }
                if (history.approval_status === "approved") {
                    setCurrentLoanStatus("Approved");
                }
                if (history.approval_status === "canceled") {
                    setLoanStatus(false);
                    setCurrentLoanStatus("Canceled");
                }
                const index = loanStatusCopy.findIndex((approver) => approver.approverId === history.approved_by);
                if (index !== -1) {
                    loanStatusCopy[index].approveDate = history.approved_date;
                    loanStatusCopy[index].approveRemark = history.remarks;
                    loanStatusCopy[index].approvalStatus = history.approval_status;
                    loanStatusCopy[index].id = history.id;
                }
            });
        }
        //reverse loan status copy
        loanStatusCopy.reverse();
        setloanHistory(loanStatusCopy);
        console.log(loanStatusCopy);
    }, [loanApproverHistory, loanApprovers]);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Paper className="gratuity-page" elevation={1} sx={{ px: 2, pt: 1, pb: 3, mb: 2 }}>
                <h3>Employee Loan Request Details</h3>
            </Paper>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Paper elevation={1} sx={{ px: 2, pt: 1, pb: 3 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">Employee</TableCell>
                                    <TableCell align="left">
                                        <Typography style={{ fontWeight: "bold" }}>{loanDetails?.employee_name}</Typography>
                                        <Typography variant="caption">{loanDetails?.designation_name ?? "N/A"}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Loan Type</TableCell>
                                    <TableCell align="left">{loanDetails?.loan_type_title}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Loan Amount</TableCell>
                                    <TableCell align="left">{loanDetails?.loan_amount}/-</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Request Date</TableCell>
                                    <TableCell align="left">{loanDetails?.created_at}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Payment Duration</TableCell>
                                    <TableCell align="left">{loanDetails?.payment_duration}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Number of Installments</TableCell>
                                    <TableCell align="left">{loanDetails?.num_of_installment}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Amount Per Installment</TableCell>
                                    <TableCell align="left">{loanDetails?.amount_per_installment}/-</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Paper elevation={1} sx={{ px: 2, pt: 1, pb: 3 }}>
                        <h5 style={{ margin: "5px 0px" }}>
                            Loan Approval Status{" "}
                            <Chip
                                color={
                                    currentLoanStatus === "Approved" ? "success" : currentLoanStatus === "Partially Approved" ? "warning" : "error"
                                }
                                size="small"
                                label={currentLoanStatus}
                            />
                        </h5>
                        <Divider />
                        <Timeline>
                            {loanStatus &&
                                loanHistory?.map((approver, index) => (
                                    <TimelineItem key={index}>
                                        <TimelineOppositeContent sx={{ m: "auto 0" }} align="right" variant="title" color="text.secondary">
                                            {approver.approverName}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot color={approver.approveDate ? "success" : "grey"}>
                                                {approver.approveDate ? <FaCheckCircle /> : <GoHourglass />}
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                                            <Typography variant="button" component="span">
                                                {approver.approveDate ? "Approved" : "Awaiting Approval"}
                                            </Typography>
                                            {!approver.approveDate && user?.employee_id === approver.approverId && (
                                                <Alert severity="info">
                                                    <Typography variant="caption" component="span">
                                                        You are the next approver
                                                    </Typography>
                                                    <button
                                                        onClick={() => {
                                                            console.log("Approver", approver);
                                                            setCurrentLoanTimeline(approver);
                                                            handleClickOpen();
                                                        }}
                                                        style={{
                                                            marginTop: 2,
                                                            cursor: "pointer",
                                                            border: "1px solid gray",
                                                            borderRadius: "4px",
                                                            background: "transparent",
                                                        }}
                                                    >
                                                        Change Approval
                                                    </button>
                                                </Alert>
                                            )}
                                            {approver.approveDate && (
                                                <Alert severity="success">
                                                    {approver?.approveDate}
                                                    <br />
                                                    {approver?.approveRemark}
                                                    <br />
                                                    {user?.employee_id === approver.approverId && (
                                                        <button
                                                            onClick={() => {
                                                                console.log("Approver", approver);
                                                                setCurrentLoanTimeline(approver);
                                                                handleClickOpen();
                                                            }}
                                                            style={{
                                                                marginTop: 2,
                                                                cursor: "pointer",
                                                                border: "1px solid gray",
                                                                borderRadius: "4px",
                                                                background: "transparent",
                                                            }}
                                                        >
                                                            Change Approval
                                                        </button>
                                                    )}
                                                </Alert>
                                            )}
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}

                            {!loanStatus &&
                                loanHistory?.map((approver, index) => (
                                    <TimelineItem key={index}>
                                        <TimelineOppositeContent sx={{ m: "auto 0" }} align="right" variant="title" color="text.secondary">
                                            {approver.approverName}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot color={"error"}>
                                                {approver?.approvalStatus === "canceled" ? (
                                                    <MdCancel />
                                                ) : approver?.approvalStatus === "approved" || approver?.approvalStatus === "pertially_approved" ? (
                                                    <FaCheckCircle />
                                                ) : (
                                                    <GoHourglass />
                                                )}
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                                            <Typography variant="body2" component="span">
                                                {approver?.approvalStatus === "canceled"
                                                    ? "Rejected"
                                                    : approver?.approvalStatus === "pertially_approved"
                                                    ? "Approved"
                                                    : "Awaiting Approval"}
                                            </Typography>
                                            {approver.approveDate && (
                                                <Alert severity="error">
                                                    {approver?.approveDate}
                                                    <br />
                                                    {approver?.approveRemark}
                                                    <br />
                                                    {user?.employee_id === approver.approverId && (
                                                        <button
                                                            onClick={() => {
                                                                console.log("Approver", approver);
                                                                setCurrentLoanTimeline(approver);
                                                                handleClickOpen();
                                                            }}
                                                            style={{
                                                                marginTop: 2,
                                                                cursor: "pointer",
                                                                border: "1px solid gray",
                                                                borderRadius: "4px",
                                                                background: "transparent",
                                                            }}
                                                        >
                                                            Change Approval
                                                        </button>
                                                    )}
                                                </Alert>
                                            )}
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                        </Timeline>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Loan Request Approval Status"}</DialogTitle>
                <Tooltip title="Close">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <IoMdCloseCircle />
                    </IconButton>
                </Tooltip>
                <Divider></Divider>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ mb: 1 }}>
                        Loan Approval Remark
                    </DialogContentText>
                    <TextField value={remarks} onChange={(e) => setRemarks(e.target.value)} sx={{ minWidth: 500 }} multiline rows={4} fullWidth />
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "space-between", px: 3, pb: 2 }}>
                    <Button size="small" onClick={statusReject} variant="outlined" color="error">
                        Reject
                    </Button>
                    <Button onClick={statusApprove} size="small" autoFocus variant="contained" color="success">
                        Approve
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LoanDetails;
