import { useQuery } from "@tanstack/react-query";
import { getLoanRequests } from "../api/fetchers/loanFetcher";

const useGetLoanRequests = ({ page, rowsPerPage }) => {
    return useQuery({
        queryKey: ["loan-requests", page, rowsPerPage],
        queryFn: () => getLoanRequests(),
    });
};

export default useGetLoanRequests;
