import { Button, Divider, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import useGetGratuitySettings from "../../hooks/useGetGratuitySettings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postGratuitySettings } from "../../api/fetchers/settingsFetcher";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
    benefit: Yup.string().required("Gratuity Benefit is required"),
    minAge: Yup.string().required("Min Job Age is required"),
    // minAgeToReceive: Yup.string().required("Min Job Age to Receive is required"),
});

const GratuitySettings = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { data: gratuitySettings, isLoading, isError } = useGetGratuitySettings();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });
    const onSubmit = (data) =>
        mutation.mutate({ gratuity_benefit: data.benefit, min_job_age_permanent: data.minAge, min_job_age_reciv_org_benefit: data.minAgeToReceive });

    const queryClient = useQueryClient();

    useEffect(() => {
        if (gratuitySettings) {
            console.log("gratuitySettings", gratuitySettings.reponse_data);
            setValue("benefit", gratuitySettings.reponse_data.gratuity_benefit);
            setValue("minAge", gratuitySettings.reponse_data.min_job_age_permanent);
            setValue("minAgeToReceive", gratuitySettings.reponse_data.min_job_age_reciv_org_benefit);
        }
    }, [gratuitySettings]);

    const mutation = useMutation({
        mutationFn: (formData) => {
            return postGratuitySettings(formData);
        },
        onSuccess: () => {
            queryClient.invalidateQueries("gratuity-settings");
            toast.success("Settings Updated Successfully");
        },

        onError: () => {
            queryClient.invalidateQueries("gratuity-settings");
            toast.error("Something went wrong");
        },
    });

    return (
        <Paper elevation={2} sx={{ p: 2, maxWidth: "500px", margin: "0 auto" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h5">Gratuity Settings</Typography>
            </Stack>
            <Divider sx={{ my: 2 }}></Divider>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Gratuity Benefit (Basic per year)</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.benefit}
                                        helperText={formState.errors.benefit ? formState.errors.benefit.message : null}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "-" || e.key === "e") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="benefit"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography>Eligibity</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Min Job Age as Permanent</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.minAge}
                                        helperText={formState.errors.minAge ? formState.errors.minAge.message : null}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "-" || e.key === "e") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="minAge"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Min Job Age to Receive Organization Benefit</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.minAgeToReceive}
                                        helperText={formState.errors.minAgeToReceive ? formState.errors.minAgeToReceive.message : null}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "-" || e.key === "e") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="minAgeToReceive"
                            control={control}
                            defaultValue=""
                        />
                    </Grid> */}
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Stack direction={"row"} justifyContent={"flex-end"}>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </Stack>
            </form>
        </Paper>
    );
};

export default GratuitySettings;
