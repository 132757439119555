import { Box, Button, MenuItem, Paper, Select, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import useAppConfig from "../../hooks/useAppConfig";
import useGetEmployeeProfile from "../../hooks/useGetEmployeeProfile";
import { useParams } from "react-router-dom";
import Docs from "../../helpers/company-docs";
import { set } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation } from "@tanstack/react-query";
import { generateDoc } from "../../api/fetchers/employeeDocFetcher";

export const options = {
    // open can also be used to open the PDF in a new window, default is false
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.MEDIUM,
        // 'letter' can also be used
        format: "A4",
        orientation: "portrait",
    },
    canvas: {
        // 'image/png' be used for better image quality
        mimeType: "image/webp",
        qualityRatio: 5,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
            compress: false,
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
            useCORS: false,
        },
    },
};

const DocumentEditPage = () => {
    const [value, setValue] = useState(
        `<p>${new Date().toDateString()}</p><p><br></p><p>To Whom It May Concern</p><p><br></p><p>We hereby issue this No Objection Certificate upon request of (Name), (Employee ID) who</p><p>worked with us as (Office Assistant), since (Date) to (30 September 2017) in (Outlet Name),</p><p>(Location Name). During his/her working tenure of (years) we found him/her loyal, punctual</p><p>and committed towards his/her work. S/he has no dues or financial transaction and s/he has</p><p>no company assets with her/him.</p><p><br></p><p>He/She has capability to do his/her tasks under stress conditions and his/her time with our</p><p>team was appreciate-able. He/She is leaving this job due to his/her personal reasons and we</p><p>have no objection where he/she will start his/her career further. We wish him/her good luck</p><p>for his/her future.</p><p>Regards,</p><p><br></p><p>(Outlet Manager’s Name) or (Respective TM/AM’s Name),</p><p>ID:</p><p>Department:</p>`
    );

    const [docFormat, setDocFormat] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [template, setTemplate] = useState(0);
    const [enabled, setEnabled] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [loading, setLoading] = useState(false);

    const pdfGenerator = async (targetRef) => {
        setLoading(true);
        try {
            await generatePDF(targetRef, options);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const { employeeId, type } = useParams();

    const { data: employeeProfile, isLoading: profileLoading, error: profileError } = useGetEmployeeProfile({ enabled, id: employeeId });

    useEffect(() => {
        if (employeeId) {
            setEnabled(true);
        }
    }, [employeeId]);

    const targetRef = useRef();

    const { data: appConfig, isLoading, isError } = useAppConfig();

    console.log({ appConfig });

    const [alignment, setAlignment] = React.useState("web");

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const docMutate = useMutation({
        mutationFn: (data) => generateDoc(data),
    });

    const saveDoc = () => {
        docMutate.mutate({
            employee_id: employeeId,
            document_type_id: type,
            description: value,
        });
    };

    useEffect(() => {
        if (employeeProfile && appConfig && employeeId) {
            setValue(Docs[0]?.template(employeeProfile.response_data, appConfig));
        }
    }, [employeeProfile, appConfig, employeeId]);

    return loading ? (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} gap={2}>
                {/* <CircularProgress color="inherit" /> */}
                <Stack spacing={1} sx={{ background: "white", padding: 2, borderRadius: 5 }}>
                    {/* For variant="text", adjust the height via font-size */}
                    {/* <Skeleton variant="text" sx={{ fontSize: "1rem" }} /> */}
                    <Skeleton variant="rectangular" width={40} height={10} />
                    <Skeleton variant="rectangular" width={60} height={10} />
                    <Skeleton variant="rectangular" width={20} height={10} />
                    <Skeleton variant="rectangular" width={180} height={30} />
                    <Skeleton variant="rectangular" width={180} height={50} />
                    <Skeleton variant="rectangular" width={20} height={10} />
                    <Skeleton variant="rectangular" width={40} height={10} />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    {/* <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" width={210} height={60} /> */}
                </Stack>
                {/* <img src="/assets/images/Download-amico.svg" alt="data" style={{ maxWidth: 200 }} /> */}
                <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                    <Typography>Please wait while we prepare your document.</Typography>
                    <Typography textAlign={"center"} variant="caption">
                        [Choose wait, if page shows unresponsive.]
                    </Typography>
                </Stack>
            </Stack>
        </Backdrop>
    ) : (
        <>
            <Paper sx={{ padding: 3 }}>
                <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"flex-end"} sx={{ mb: 2 }}>
                    <Typography>Select Template: </Typography>
                    <Select
                        defaultValue={0}
                        size="small"
                        onChange={(e) =>
                            setValue(
                                Docs[e.target.value].template(
                                    //     {
                                    //     name: employeeProfile?.response_data?.name,
                                    //     employeeId: employeeProfile?.response_data?.hrm_employee_id,
                                    //     designation: employeeProfile?.response_data?.designation_name,
                                    //     joiningDate: employeeProfile?.response_data?.join_date,
                                    //     separationDate: employeeProfile?.response_data?.separation_date,
                                    //     branch: employeeProfile?.response_data?.branch_name,
                                    //     workingYears: employeeProfile?.response_data?.total_working_years,
                                    //     company: appConfig?.setting_app_company_name,
                                    //     companyAddress: appConfig?.setting_app_address,
                                    // }
                                    employeeProfile.response_data,
                                    appConfig
                                )
                            )
                        }
                    >
                        {Docs.map((doc, index) => (
                            <MenuItem key={index} value={index}>
                                {doc.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Box sx={{ maxWidth: 580, margin: "0 auto" }}>
                    <ReactQuill
                        modules={{
                            toolbar: [
                                [{ header: [] }, { font: [] }],
                                [{ size: [] }],
                                ["bold", "italic", "underline", "strike", "blockquote"],
                                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                                ["link", "image", "video"],
                                ["clean"],
                                [{ color: [] }, { background: [] }],
                                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
                                // [],
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: true,
                            },
                        }}
                        formats={[
                            "header",
                            "font",
                            "size",
                            "color",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                            "image",
                            "video",
                            "align",
                        ]}
                        style={{
                            backgroundColor: "#fff",
                            color: "#000",
                            minHeight: "300px",
                            maxHeight: "600px",
                            overflowY: "auto",
                        }}
                        theme="snow"
                        value={value}
                        // onChange={setValue}
                        onChange={(v) => {
                            setValue(v);
                            console.log(v);
                        }}
                    />
                </Box>
                <Stack direction={"row"} justifyContent={"flex-end"} gap={2} sx={{ mt: 2 }}>
                    <Button size="small" variant="contained" onClick={saveDoc}>
                        Save
                    </Button>
                    <Button size="small" variant="contained" onClick={handleClickOpen}>
                        Preview
                    </Button>
                </Stack>
            </Paper>
            <Dialog maxWidth={"lg"} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle>{"Document Preview"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div
                            style={{
                                maxWidth: "680px",
                                display: "inline-block",
                                color: "#000",
                            }}
                            ref={targetRef}
                            dangerouslySetInnerHTML={{ __html: value }}
                        ></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Disagree
                    </Button>
                    <Button size="small" onClick={() => pdfGenerator(targetRef)}>
                        Download Doc
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DocumentEditPage;
