import { useQuery } from "@tanstack/react-query";
import { getLoanApprovers } from "../api/fetchers/loanFetcher";

const useGetLoanApproversList = ({ page = null, limit = null }) => {
    return useQuery({
        queryKey: ["loan-approvers-list", page, limit],
        queryFn: () => getLoanApprovers(page, limit),
    });
};

export default useGetLoanApproversList;
