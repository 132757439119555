import { useQuery } from "@tanstack/react-query";
import { getAllBranches } from "../api/fetchers/dropdownData";
import { getManageProvidentFund } from "../api/fetchers/providentFund";

const useGetManageProvidentFund = ({ page, rowsPerPage }) => {
    return useQuery({
        queryKey: ["manage-provident-fund", page, rowsPerPage],
        queryFn: () => getManageProvidentFund(),
    });
};

export default useGetManageProvidentFund;
