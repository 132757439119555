import {
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useEffect, useState } from "react";
import { Controller, set, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import useGetLoanRequests from "../../hooks/useGetLoanRequest";
import useGetAllEmployees from "../../hooks/useGetAllEmployees";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useGetAllLoanTypes from "../../hooks/useGetAllLoanTypes";
import { createLoanRequest } from "../../api/fetchers/loanFetcher";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    maxHeight: 800,
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required("Employee name is required"),
    loanType: Yup.string().required("Loan type is required"),
    loanAmount: Yup.number("Must be a number").required("Amount is required"),
    paymentDuration: Yup.string().required("Duration is required"),
    // paymentType: Yup.string().required("Payment type is required"),
    installments: Yup.string().required("Installments is required"),
    // amountPerinstallment: Yup.string().required("Amount per installment is required"),
});

const RequestLoan = () => {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedFund, setSelectedFund] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [loanAmount, setLoanAmount] = useState(0);
    const [paymentDuration, setPaymentDuration] = useState(0);
    const [loanAttachment, setLoanAttachment] = useState(null);

    const menuOpen = Boolean(anchorEl);
    const handleMenuOpen = (event, selectedFund) => {
        setSelectedFund(selectedFund);
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("employee_id", data.employeeName);
        formData.append("loan_amount", data.loanAmount);
        formData.append("num_of_installment", data.installments);
        formData.append("attachment", loanAttachment);
        formData.append("loan_type_id", data.loanType);
        formData.append("payment_duration", data.paymentDuration);
        formData.append("loan_details", data.details);
        formData.append("amount_per_installment", data.amountPerinstallment);
        loanMutate.mutate(formData);
    };

    useEffect(() => {
        if (parseFloat(loanAmount ?? 0) === 0) {
            setValue("amountPerinstallment", 0);
        }

        if (parseFloat(paymentDuration ?? 0) === 0) {
            setValue("amountPerinstallment", 0);
        }

        if (parseFloat(loanAmount ?? 0) !== 0 && parseFloat(paymentDuration ?? 0) !== 0) {
            const perInstAmount = (parseFloat(loanAmount ?? 0) / parseFloat(paymentDuration ?? 0)).toFixed(2);
            setValue("amountPerinstallment", perInstAmount);
        }

        console.log("loanAmount");
    }, [loanAmount, paymentDuration]);

    const { data: loanRequests, isLoading: loanRequestLoading, isError: loanRequestError } = useGetLoanRequests({ page, rowsPerPage });
    const { data: allEmployees, isLoading: allEmployeesLoading, isError: allEmployeesError } = useGetAllEmployees();
    const { data: loanTypes, isLoading: loanTypesLoading, isError: loanTypeError } = useGetAllLoanTypes();

    const queryClient = useQueryClient();

    const loanMutate = useMutation({
        mutationFn: (data) => {
            return createLoanRequest(data);
        },
        onSuccess: (data) => {
            console.log("data", data);
            queryClient.invalidateQueries("loan-requests");
            handleClose();
        },
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper elevation={2} sx={{ p: 2 }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h5">Loan Requests</Typography>

                <Button sx={{ mr: 1 }} variant={"contained"} size={"small"} color={"success"} onClick={handleOpen}>
                    Create Loan Request
                </Button>
            </Stack>
            <Divider sx={{ my: 2 }}></Divider>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">SL</TableCell>
                            <TableCell align="center">Emp ID</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Emp Designation</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Installments</TableCell>
                            <TableCell align="center">Amount Per Installments</TableCell>
                            <TableCell align="center">Approval Status</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanRequestLoading && (
                            <TableRow>
                                <TableCell align="center" colSpan={9}>
                                    Loading...
                                </TableCell>
                            </TableRow>
                        )}
                        {loanRequestError && (
                            <TableRow>
                                <TableCell align="center" colSpan={9}>
                                    No Data Found
                                </TableCell>
                            </TableRow>
                        )}
                        {loanRequests?.data?.map((row, index) => (
                            <TableRow key={row.SL} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="center">{row?.hrm_employee_id}</TableCell>
                                <TableCell align="center">{row?.employee_name}</TableCell>
                                <TableCell align="center">{row?.designation_name}</TableCell>
                                <TableCell align="center">{row?.loan_amount} /-</TableCell>
                                <TableCell align="center">{row?.num_of_installment}</TableCell>
                                <TableCell align="center">
                                    {(parseFloat(row?.loan_amount ?? 0) / parseFloat(row?.num_of_installment ?? 0)).toFixed(2)} /-
                                </TableCell>
                                <TableCell align="center">
                                    <Chip
                                        label={
                                            row.approval_status === "pending"
                                                ? "Pending"
                                                : row.approval_status === "approved"
                                                ? "Approved"
                                                : row.approval_status === "pertially_approved"
                                                ? "Partially Approved"
                                                : "Rejected"
                                        }
                                        size="small"
                                        color={
                                            row.approval_status === "pending"
                                                ? "warning"
                                                : row.approval_status === "approved"
                                                ? "success"
                                                : row.approval_status === "pertially_approved"
                                                ? "warning"
                                                : "error"
                                        }
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        id="basic-button"
                                        aria-controls={menuOpen ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={menuOpen ? "true" : undefined}
                                        onClick={(event) => handleMenuOpen(event, row)}
                                    >
                                        <BsThreeDotsVertical />
                                    </IconButton>
                                </TableCell>
                                {/* <TableCell align="right">
                                    <FaEdit />
                                    <MdDeleteForever />
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={loanRequests?.total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem>
                    <Link to={`/loan/${selectedFund.id}`} style={{ textDecoration: "none", color: "black" }}>
                        View Details
                    </Link>
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>Set {selectedFund.Status === "Active" ? "Inactive" : "Active"}</MenuItem>
                <MenuItem onClick={handleClose}>Cancel Loan</MenuItem> */}
            </Menu>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Request Loan
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} sx={{ my: 2 }} alignItems="center" justifyContent="end">
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Employee Name*</Typography>
                                            <Select {...field} error={!!formState.errors?.employeeName}>
                                                {allEmployees?.response_data?.map((employee) => (
                                                    <MenuItem value={employee?.id}>
                                                        {employee?.name} - ({employee?.hrm_employee_id})
                                                    </MenuItem>
                                                ))}
                                                {allEmployeesLoading && <MenuItem>Loading...</MenuItem>}
                                                {allEmployeesError && <MenuItem>No Data Found</MenuItem>}
                                            </Select>
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography color={!!formState.errors?.loanType ? red[700] : ""}>Loan Type*</Typography>
                                            <Select {...field} error={!!formState.errors?.loanType}>
                                                {loanTypes?.map((type) => (
                                                    <MenuItem value={type?.id}>{type?.title}</MenuItem>
                                                ))}
                                                {loanTypesLoading && <MenuItem>Loading...</MenuItem>}
                                                {loanTypeError && <MenuItem>No Data Found</MenuItem>}
                                            </Select>
                                            {!!formState.errors?.loanType ? <FormHelperText error>{errors?.loanType?.message}</FormHelperText> : ""}
                                        </FormControl>
                                    )}
                                    name="loanType"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.loanAmount ? red[700] : ""}>Loan Amount*</Typography>
                                            <OutlinedInput
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setLoanAmount(e.target.value);
                                                }}
                                                type={"number"}
                                                error={!!formState.errors?.loanAmount}
                                            />
                                            {!!formState.errors?.loanAmount ? (
                                                <FormHelperText error>{errors?.loanAmount?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="loanAmount"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.paymentDuration ? red[700] : ""}>
                                                Payment Duration (in months)*
                                            </Typography>
                                            <OutlinedInput {...field} type={"number"} error={!!formState.errors?.paymentDuration} />
                                            {!!formState.errors?.paymentDuration ? (
                                                <FormHelperText error>{errors?.paymentDuration?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="paymentDuration"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Request Date
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"date"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography color={!!formState.errors?.paymentType ? red[700] : ""}>Payment Type*</Typography>
                                            <Select {...field} error={!!formState.errors?.paymentType}>
                                                <MenuItem value={"intern software engineer"}>Weekly</MenuItem>
                                                <MenuItem value={"trainee software engineer"}>Twice a month</MenuItem>
                                                <MenuItem value={"associate software engineer"}>Monthly</MenuItem>
                                                <MenuItem value={"software engineer"}>Quarterly</MenuItem>
                                            </Select>
                                            {!!formState.errors?.paymentType ? (
                                                <FormHelperText error>{errors?.paymentType?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="paymentType"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Duration (in months)*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Total Premium*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.installments ? red[700] : ""}>No. of Installments*</Typography>
                                            <OutlinedInput
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setPaymentDuration(e.target.value);
                                                }}
                                                type={"number"}
                                                error={!!formState.errors?.installments}
                                            />
                                            {!!formState.errors?.installments ? (
                                                <FormHelperText error>{errors?.installments?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="installments"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.amountPerinstallment ? red[700] : ""}>
                                                Amount Per Installment*
                                            </Typography>
                                            <OutlinedInput disabled {...field} type={"number"} error={!!formState.errors?.amountPerinstallment} />
                                            {!!formState.errors?.amountPerinstallment ? (
                                                <FormHelperText error>{errors?.amountPerinstallment?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="amountPerinstallment"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.attachment ? red[700] : ""}>Attachment</Typography>
                                            <OutlinedInput
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setLoanAttachment(e.target.files[0]);
                                                }}
                                                type={"file"}
                                                error={!!formState.errors?.attachment}
                                            />
                                            {!!formState.errors?.attachment ? (
                                                <FormHelperText error>{errors?.attachment?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="attachment"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography>Note</Typography>
                                            <TextField multiline rows={3} {...field} error={!!formState.errors?.details} />

                                            {!!formState.errors?.details ? <FormHelperText error>{errors?.details?.message}</FormHelperText> : ""}
                                        </FormControl>
                                    )}
                                    name="details"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Required Within*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"date"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Repayment From*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"date"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Percentage (%)*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Number of Premiums*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Repayment Total*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography
                                                color={
                                                    !!formState.errors?.employeeName ? red[700] : ""
                                                }
                                            >
                                                Installment ($)*
                                            </Typography>
                                            <OutlinedInput
                                                {...field}
                                                type={"number"}
                                                error={!!formState.errors?.startDate}
                                            />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>
                                                    {errors?.employeeName?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography
                                                color={!!formState.errors?.position ? red[700] : ""}
                                            >
                                                Status*
                                            </Typography>
                                            <Select {...field} error={!!formState.errors?.position}>
                                                <MenuItem value={"intern software engineer"}>
                                                    Active
                                                </MenuItem>
                                                <MenuItem value={"trainee software engineer"}>
                                                    Inactive
                                                </MenuItem>
                                                <MenuItem value={"associate software engineer"}>
                                                    Completed
                                                </MenuItem>
                                                <MenuItem value={"software engineer"}>
                                                    Canceled
                                                </MenuItem>
                                            </Select>
                                            {!!formState.errors?.position ? (
                                                <FormHelperText error>
                                                    {errors?.position?.message}
                                                </FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="position"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <Button sx={{ mt: 3 }} variant="contained" type="submit" fullWidth>
                                    Create Loan Request
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </Paper>
    );
};

export default RequestLoan;
