import { useQuery } from "@tanstack/react-query";
import { getManageGratuity } from "../api/fetchers/gratuity";

const useGetManageGratuity = () => {
    return useQuery({
        queryKey: ["manage-gratuity"],
        queryFn: () => getManageGratuity(),
    });
};

export default useGetManageGratuity;
